<template>
  <div class="container">
    <div
      class="left-menu"
      :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }"
    >
      <left-list :leftIndex="'7'" :isCollapse="isCollapse"></left-list>
    </div>
    <div
      class="right-content"
      :style="{ width: !isCollapse ? '88%' : '95.5%' }"
    >
      <div class="top-nav">
        <top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
      </div>
      <div class="left-list">
        <div class="left-list-title">
          <span>闲赚</span>
        </div>
        <div class="left-list-item" @click="leftClick('0')">
          <span>申请列表</span>
        </div>
        <div class="left-list-item" @click="leftClick('1')">
          <span>闲赚伙伴</span>
        </div>
        <div class="left-list-item left-list-active" @click="leftClick('2')">
          <span>闲赚订单</span>
        </div>
      </div>
      <div class="right-next">
        <div class="title">
          <span>闲赚订单</span>
        </div>
        <div class="date-search">
          <el-select
            class="ml-40"
            v-model="state"
            placeholder="订单状态"
            clearable
          >
            <el-option
              v-for="item in stateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input
            class="ml-40"
            v-model="inpuValue"
            placeholder="真实姓名"
          ></el-input>
          <el-button icon="el-icon-search" type="primary" @click="selectBtn"
            >搜索</el-button
          >
          <el-button type="primary">导出</el-button>
        </div>
        <div class="mt-20">
          <el-table :data="tableData.data" border style="width: 100%">
            <el-table-column
              prop="order_on"
              label="订单号"
              width="200"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="date"
              label="商品首图"
              width="150"
              align="center"
            >
              <template slot-scope="scope">
                <el-image
                  style="width: 100%; height: 100px"
                  :src="
                    scope.row.goods.shopImg
                      ? $store.state.imaUrl + scope.row.goods.shopImg
                      : ''
                  "
                  :preview-src-list="srcList"
                  @click="imageClick($store.state.imaUrl + scope.row.goods.shopImg)"
                >
                </el-image>
              </template>
            </el-table-column>
            <el-table-column
              prop="link_name"
              label="卖家"
              width="120"
              align="center"
            >
            </el-table-column>
            <!-- <el-table-column prop="name" label="类型" width="120" align="center">
            </el-table-column> -->

            <el-table-column
              prop="price"
              label="成交金额"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="dealer_price"
              label="佣金"
              width="100"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="订单状态"
              width="150"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.status == 0">待发货</span>
                <span v-if="scope.row.status == 2">运输中</span>
                <span v-if="scope.row.status == 3">鉴定中</span>
                <span v-if="scope.row.status == 4">鉴定成功</span>
                <span v-if="scope.row.status == 5">鉴定为假货</span>
                <span v-if="scope.row.status == 6">卖家拒绝售卖</span>
                <span v-if="scope.row.status == 7">已完成</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="express_on"
              label="顺丰订单号"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
        <div class="bottom-paging">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[5, 10, 20, 30, 100]"
            :page-size="num"
            layout="total, prev, pager, next, sizes"
            :total="tableData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import leftList from "../../../components/leftList.vue";
import topHeader from "../../../components/header.vue";
export default {
  components: {
    leftList,
    topHeader,
  },
  inject: ["reload"],
  data() {
    return {
      tableData: [],
      isCollapse: false,
      stateList: [
        {
          value: "0",
          label: "待发货",
        },
        {
          value: "2",
          label: "运输中",
        },
        {
          value: "3",
          label: "鉴定中",
        },
        {
          value: "4",
          label: "鉴定成功",
        },
        {
          value: "5",
          label: "鉴定为假货",
        },
        {
          value: "7",
          label: "已完成",
        },
      ],
      srcList: ['https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg'],
      state: "",
      inpuValue: "",
      pickerOptions: {
        //控制时间范围
        disabledDate(time) {
          return time.getTime() > Date.now() - 24 * 60 * 1000;
        },
      },
      page: 1,
      num: 10,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    imageClick(url){
      this.srcList = [];
      this.srcList.push(url);
    },
    selectBtn() {
      this.page = 1;
      this.getList();
    },
    getList() {
      this.$get("get/order", {
        page: this.page,
        num: this.num,
        status: this.state == "" ? "null" : this.state,
        dealer_id: this.$route.query.id ? this.$route.query.id : 0,
        name: this.inpuValue,
      }).then((res) => {
        this.tableData = res.data.data;
        for (let i = 0; i < this.tableData.data.length; i++) {
          if (this.tableData.data[i].goods.file_json != null) {
            this.tableData.data[i].goods.file_json = JSON.parse(
              this.tableData.data[i].goods.file_json
            );
            this.tableData.data[i].goods.shopImg = this.tableData.data[
              i
            ].goods.file_json[0].files;
          } else {
            this.tableData.data[i].goods.shopImg = null;
          }
        }
        console.log(this.tableData.data);
      });
    },
    menuitemClick(pushUrl) {
      // console.log(this.$route.path == pushUrl);
      if (this.$route.path == pushUrl) {
        this.reload();
      } else if (pushUrl) {
        this.$router.push(pushUrl);
      } else {
        this.$notify.error({
          title: "提示",
          message: "暂无页面",
        });
        this.reload();
      }
    },
    leftClick(index) {
      if (index == 0 && this.$route.path != "/idleearning") {
        this.$router.push("/idleearning");
      } else if (index == 1 && this.$route.path != "/partner") {
        this.$router.push("/partner");
      } else if (index == 1 && this.$route.path != "/idleearningorder") {
        this.$router.push("/idleearningorder");
      } else {
        this.reload();
      }
    },
    openMenu() {
      this.isCollapse = !this.isCollapse;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.num = val;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getList();
    },
    handleEdit(index, row) {
      this.$router.push({
        name: "idleearninginfo",
        params: {
          row: row,
        },
      });
      //   console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
  },
};
</script>

<style lang="scss" scoped>
.right-content {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  vertical-align: top;
  display: inline-block;
  &::-webkit-scrollbar {
    display: none;
  }
  .teamSize {
    color: #3291f8;
    cursor: pointer;
  }
  .date-search {
    text-align: right;
    margin-top: 30px;
    .el-button {
      margin-left: 40px;
      padding: 12px 35px;
    }
    .el-input {
      width: 200px;
    }
  }
  .el-table {
    .el-button--mini {
      padding: 7px 10px;
    }
  }
  .left-list {
    width: 10%;
    height: 93vh;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 14px;
    border-right: 20px solid #f2f2f2;
    display: inline-block;
    text-align: center;
    &::-webkit-scrollbar {
      display: none;
    }
    &-title {
      margin: 20px 0;
      font-size: 16px;
    }
    &-item {
      padding: 15px 0;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        color: #fff;
        background-color: #3291f8;
      }
    }
    &-active {
      color: #fff;
      background-color: #3291f8;
    }
  }
  .right-next {
    height: 93vh;
    width: 88.5%;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: 20px solid #f2f2f2;
    padding: 20px;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .title {
    color: #000;
    height: 10px;
    padding-left: 15px;
    line-height: 10px;
    border-left: 5px solid #80c4f8;
    margin-bottom: 10px;
  }

  .top-nav {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 15px;

    .left-icon {
      i {
        cursor: pointer;
        color: #3291f8;
        font-size: 30px;
      }

      .el-icon-refresh-right {
        margin-left: 30px;
      }
    }

    .right-user {
      font-size: 14px;

      span {
        vertical-align: middle;
        margin-right: 20px;
      }

      .colse-btn {
        cursor: pointer;
        vertical-align: middle;
        border: 1px solid #999;
        display: inline-block;
        padding: 5px 20px;
        border-radius: 50px;

        span {
          margin-right: 10px;
        }

        i {
          vertical-align: middle;
        }
      }
    }
  }
}

.left-menu {
  display: inline-block;

  .iconyonghuzu {
    font-size: 18px;
    margin: 0 5px;
  }
}

.el-menu {
  width: 100%;
  background-color: #20222a;
  height: 100vh;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  background-color: #20222a;
  color: #fff;
  height: 100vh;
  width: 100%;
}

.el-menu-item:focus,
.el-menu-item:hover {
  color: #000;
  background-color: #ecf5ff;
}

.el-menu-item {
  color: #fff;

  i {
    color: #3291f8;
  }
}

.el-menu-item.is-active {
  background-color: #ecf5ff;
  color: #20222a;
}

.menu-h5 {
  margin: 20px;
  text-align: center;
  font-size: 20px;
}

.menu-icon {
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  padding: 30px 22px;
}
</style>
